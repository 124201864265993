import { create } from "zustand";
import { persist } from "zustand/middleware";

export const userStore = create(
    persist(
        (set) => ({
            user: null, // Añadimos el estado `user`
            setUser: (user) => set({ user }), // Función para establecer el usuario
            removeUser: () => set({ user: null }) // Función para eliminar el usuario
        }),
        {
            name: 'user' // Nombre del almacenamiento persistente
        }
    )
);
