import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HttpAuth } from "../../Api/HttpAuth";
import { useSeller } from "../../Context/SellerProvider";

export const Register = () => {

    const { seller } = useSeller();

    const navigate = useNavigate();

    const [errors, setErrors] = useState("")
    const [passed, setPassed] = useState("")
    const [formData, setFormData] = useState({})

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrors("");
        const { telefono } = formData
        const telFin = 593+telefono
        setFormData(prevFormData => ({ ...prevFormData, telefono: telFin }))
        HttpAuth.Register(formData)
            .then((response) => {
                if(response.status === 403){
                    setErrors(response.data.message)
                    setTimeout(function(){
                        setErrors(null)
                    }, 2000);
                }else{
                    setPassed(response.data.status)
                    setTimeout(function(){
                        setPassed(null)
                        seller === "Register" ? navigate('/Login') : navigate(`/Login/${seller}`)
                    }, 2000);
                }
            })
    }

    return (
        <div className="min-h-[84.2vh] flex justify-center items-center">

            <div className="bg-custom-gray mx-7 w-full max-w-[570px] p-5 rounded-custom">
                <p className='text-4xl font-bold text-green-500 text-center'>
                    Registrate
                </p>
                <p className='text-lg text-center mt-3'>
                    Please login using account detail bellow.
                </p>
                <form
                    onSubmit={handleSubmit}
                >
                    <div className="flex flex-wrap justify-between w-full">
                        <div className="w-full md:w-[48.5%] mt-5">
                            <input
                                type="email"
                                required
                                placeholder="email"
                                className='input-style w-full'
                                style={{margin:0}}
                                onChange={event =>
                                    setFormData(prevFormData => ({ ...prevFormData, email: event.target.value }))
                                }
                            />
                        </div>
                        <div className="w-full md:w-[48.5%] mt-5">
                            <input
                                type="password"
                                required
                                placeholder="********"
                                className='input-style w-full'
                                style={{margin:0}}
                                onChange={event =>
                                    setFormData(prevFormData => ({ ...prevFormData, password: event.target.value }))
                                }
                            />
                        </div>
                        <div className="w-full md:w-[48.5%] mt-5">
                            <input
                                type="text"
                                required
                                placeholder="Nombre"
                                className='input-style w-full'
                                style={{margin:0}}
                                onChange={event =>
                                    setFormData(prevFormData => ({ ...prevFormData, names: event.target.value }))
                                }
                            />
                        </div>
                        <div className="w-full md:w-[48.5%] mt-5">
                            <input
                                type="number"
                                required
                                placeholder="Teléfono"
                                className='input-style w-full'
                                style={{margin:0}}
                                onChange={event =>
                                    setFormData(prevFormData => ({ ...prevFormData, telefono: event.target.value }))
                                }
                            />
                        </div>
                        <div className="w-full mt-5">
                            <textarea
                                required
                                placeholder="Dirrecion de d onde vive para las entregas"
                                className='input-style w-full'
                                style={{margin:0, minHeight:80}}
                                onChange={event =>
                                    setFormData(prevFormData => ({ ...prevFormData, direccion: event.target.value }))
                                }
                            />
                        </div>
                    </div>
                    <button type="submit" className="btn-primary w-full mt-5">
                        Crear Cuenta
                    </button>
                </form>
                <span className='block text-center mt-2 text-sm text-red-600'>
                    {errors}
                </span>
                <span className='block text-center mt-2 text-sm text-green-500'>
                    {passed}
                </span>
                <div className="flex justify-center items-center mt-4 hover:text-green-500">
                    <div className="cursor-pointer" onClick={() => {seller === "Register" ? navigate('/Login') : navigate(`/Login/${seller}`)}}>
                        Entrar
                    </div>
                </div>
            </div>

        </div>
    )
}