import { BrowserRouter } from 'react-router-dom';
import { AppRouter } from './Router/AppRouter';
import ToastProvider from './Context/ToastContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <>
      <ToastProvider>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </ToastProvider>
      <ToastContainer />
    </>
  );
}

export default App;
