import { useEffect, useState } from "react"
import { userStore } from "../../Store"
import { HttpConsultas } from "../../Api/HttpConsultas"
import { TbSquareRoundedX } from "react-icons/tb"

export const Paid = () => {

    const user = userStore(state => state.user)

    const [data, setdata] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [dataModal, setdataModal] = useState({})

    useEffect(() => {
        HttpConsultas.Paid(user.user.id)
            .then((response) => {
                console.log(response)
                if (response.status === 200) {
                    setdata(response.data)
                }
            })
    }, [])

    const handleModal = (item) => {

        setShowModal(true)

        setdataModal(item)

    }


    return (
        <div className="mx-auto px-3.5 max-w-7xl md:max-w-3xl xl:max-w-6xl w-full">
            <div className="w-full py-8">
                {
                    data.length === 0 ?
                        (<h1 className="text-4xl font-extrabold text-center">No hay pagos realizados</h1>)
                        :
                        data.map(e => (
                            <div key={e.id} className="w-full border border-green-500 rounded-xl p-4 mb-4 cursor-pointer" onClick={() => handleModal(e)}>
                                {
                                    e.id_products.map(item => (
                                        <div className="w-full flex" key={item.id}>
                                            <img src={item.img} alt="" className="w-32 h-32" />
                                            <div className="w-full ms-3">
                                                <p className="font-bold">
                                                    {
                                                        item.name
                                                    }
                                                </p>
                                                {
                                                    item.precio_oferta == null ? (
                                                        <p className="font-bold">
                                                            {item.qty} x ${item.price}
                                                        </p>
                                                    )
                                                        :
                                                        (
                                                            <p className="font-bold">
                                                                {item.qty} x ${item.precio_oferta}
                                                            </p>
                                                        )
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className="w-full border"></div>
                                <h1 className="m-3 text-2xl font-bold">
                                    Total: <span className="text-green-500">
                                        ${e.id_products.reduce((acc, product) => {
                                            // Parse the price and quantity to ensure they are numbers
                                            const price = parseFloat(String(product.price).replace(',', '.')) || 0;
                                            const qty = parseFloat(String(product.qty).replace(',', '.')) || 0;
                                            return acc + price * qty;
                                        }, 0).toFixed(2)}
                                    </span>
                                </h1>
                            </div>
                        ))
                }
            </div>

            {showModal && (<div className="fixed top-0 left-0 w-screen h-screen z-10 bg-black opacity-30" />)}


            {showModal && (<div className="fixed w-full h-screen backdrop-filter backdrop-blur-smbg-green-500 z-50 top-0 left-0 flex justify-center items-center">
                <div className="w-7/12 h-[70%] bg-white rounded-3xl relative p-16">
                    <div className="absolute top-4 right-4 cursor-pointer" onClick={() => setShowModal(false)}>
                        <TbSquareRoundedX className="w-10 h-10 text-black hover:text-green-500" />
                    </div>
                    <div className="w-full h-full overflow-y-auto">
                        <div className="w-full flex bg-gray-300 py-2 px-4 font-bold">
                            <div className="w-4/12">Producto</div>
                            <div className="w-4/12 flex justify-center">Cantidad</div>
                            <div className="w-4/12 flex justify-end">Precio</div>
                        </div>
                        {
                            dataModal.id_products.map(item => (
                                <div className="w-full flex py-2 px-4 items-center">
                                    <div className="w-4/12">{item.name}</div>
                                    <div className="w-4/12 flex justify-center">{item.qty}</div>
                                    <div className="w-4/12 flex justify-end">
                                        {
                                            item.precio_oferta == null ? (
                                                <p className="font-bold">
                                                    ${item.price}
                                                </p>
                                            )
                                                :
                                                (
                                                    <p className="font-bold">
                                                        ${item.precio_oferta}
                                                    </p>
                                                )
                                        }
                                    </div>
                                </div>
                            ))
                        }
                        <div className="w-full border"></div>
                        <div className="w-full flex justify-between">
                            <h1 className="m-3 text-2xl text-green-500 font-bold">Total:</h1>

                            <h1 className="m-3 text-2xl text-green-500 font-bold">${dataModal.total_price}</h1>
                        </div>
                        <div className="w-full border"></div>
                        <div className="w-full flex justify-between py-2 px-4">
                            <p className="font-bold">Nombre:</p>
                            <p className="font-semibold">{dataModal.seller_name}</p>
                        </div>
                        <div className="w-full flex justify-between py-2 px-4">
                            <p className="font-bold">Teléfono:</p>
                            <p className="font-semibold">{dataModal.seller_phone}</p>
                        </div>
                        <div className="w-full flex justify-between py-2 px-4">
                            <p className="font-bold">Codigo de compra:</p>
                            <p className="font-bold">{dataModal.code_user}</p>
                        </div>
                        <div className="w-full flex justify-between py-2 px-4">
                            <p className="font-bold">Fecha:</p>
                            <p className="font-semibold">{dataModal.created_at}</p>
                        </div>
                    </div>
                </div>
            </div>)}

        </div>
    )
}