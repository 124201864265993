import { toast } from "react-toastify";
import React, { useEffect } from 'react';  // Make sure to import React
import { useCartStore } from "../Store";

const ToastProvider = ({ children }) => {
    const sms = useCartStore(state => state.smsCompra);
    const getSmsCompra = useCartStore(state => state.getSmsCompra);

    useEffect(() => {
        if (sms.length !== 0) {
            toast.success(sms);
            getSmsCompra("");
        }
    }, [sms, getSmsCompra]);

    return <>{children}</>;
}

export default ToastProvider;
