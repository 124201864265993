import { useNavigate } from "react-router-dom"
import { useCartStore, userStore, useUIStore } from "../../../Store"
import { useSeller } from "../../../Context/SellerProvider"

export const TotalSidebar = ({ total, setComprar }) => {

  const closeMenu = useUIStore(state => state.closeSideMenu)
  const user = userStore(state => state.user)
  const car = useCartStore(state => state.cart)

  
  const navigate = useNavigate();
  const { seller } = useSeller();

  const handleSubmit = () => {

    if(user === null){
      closeMenu()
      navigate(`/login/${seller}`)
    }else{
      const dataProducts = car.map((e) => (
        {
          id: e.id,
          qty: e.qty,
          price: e.price
        }
      ))
  
      const data = {
        nick: seller,
        id_user: user.user.id,
        id_products: JSON.stringify(dataProducts)
      }
      
      setComprar(data)
    }
  }



  return (
    <div className=" border-y mt-5 py-3">

      <div className="flex flex-wrap justify-between mb-3">
        <span className='text-2xl font-bold' >
          Total:
        </span>
        <span className='text-2xl font-bold text-green-500'>
          ${total}
        </span>
      </div>

      <button className="btn-primary w-full" onClick={ () => handleSubmit() } >
        Comprar Ahora
      </button>
    </div>
  )

}