import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Contact, Home, Login, Product, Products, Register, About, Paid } from '../View/index';
import { Footer, LateralMenu, MenuMobil, Sidebar, TopMenu } from "../Components";
import { SellerProvider } from "../Context/SellerProvider";

export const AppRouter = () => {
    return (
        <SellerProvider>
            <MenuMobil />
            <TopMenu />
            <LateralMenu />
            <Sidebar />
            <Routes>
                <Route path="/Login/:nick?" element={<Login />} />
                <Route path="/Register/:nick?" element={<Register />} />
                <Route path="/:nick?" element={<Home />} />
                <Route path="/Contact/:nick?" element={<Contact />} />
                <Route path="/About/:nick?" element={<About />} />
                <Route path="/Product/:id/:nick?" element={<Product />} />
                <Route path="/Products/:nick?" element={<Products />} />
                <Route path="/Paid/:nick?" element={<Paid />} />
            </Routes>
            <Footer />
        </SellerProvider>
    );
};
