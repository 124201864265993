import clsx from "clsx";
import { useCartStore, useUIStore } from "../../../Store";
import { TbSquareRoundedX } from "react-icons/tb";
import { useState } from "react";
import { ProductsSidebar } from "./ProductsSidebar";
import { TotalSidebar } from "./TotalSidebar";
import { HttpConsultas } from "../../../Api/HttpConsultas";
import { useSeller } from "../../../Context/SellerProvider";

export const Sidebar = () => {
  const { seller } = useSeller();

  const getSmsCompra = useCartStore((state) => state.getSmsCompra);
  const removeAllProduct = useCartStore((state) => state.removeAllProduct);
  const isSideMenuOpen = useUIStore((state) => state.isSideMenuOpen);
  const closeMenu = useUIStore((state) => state.closeSideMenu);
  const productsInCart = useCartStore((state) => state.cart);

  const total = productsInCart
    .reduce((acc, product) => {
      const price = parseFloat(String(product.price).replace(',', '.')) || 0; // Asegura que el precio sea un número
      const qty = parseFloat(String(product.qty).replace(',', '.')) || 0; // Asegura que la cantidad sea un número
      return acc + price * qty;
    }, 0)
    .toFixed(2); // Asegura que el total tenga dos decimales

  const [showModal, setShowModal] = useState(false);
  const [dataFinal, setdataFinal] = useState({});
  const [nickInput, setNickInput] = useState(false);
  const [nickInputFalse, setNickInputFalse] = useState(false);
  const [nickInputInput, setnickInputInput] = useState(false);
  const [nick, setnick] = useState("");

  const fetchComprar = (data) => {
    HttpConsultas.RegistrarProducto(data).then((response) => {
      if (response.status === 200) {
        getSmsCompra(`compra exitosa su codigo es: ${response.data.venta.code_user}`);
        removeAllProduct();
        closeMenu();
        setShowModal(false);
        setNickInput(false);
        setNickInputFalse(false);
      } else if (response.status === 403) {
        setNickInputFalse(true);
        setNickInput(false);
        setnickInputInput(true);
      } else {
        setNickInput(true);
        setNickInputFalse(false);
        setnickInputInput(true);
      }
    });
  };

  const setComprar = (data) => {
    if (nick.length !== 0) {
      const { id_products, id_user } = data;
      const dataFin = {
        id_products,
        id_user,
        nick,
      };
      fetchComprar(dataFin);
    } else {
      if (seller === null || ["Products", "Product", "About", "Contact", "Paid"].includes(seller)) {
        setNickInput(true);
        setNickInputFalse(false);
        setnickInputInput(true);
      } else {
        fetchComprar(data);
      }
    }
  };

  return (
    <div>
      {isSideMenuOpen && (
        <>
          <div className="fixed top-0 left-0 w-screen h-screen z-10 bg-black opacity-30" />
          <div onClick={closeMenu} className="fade-in fixed top-0 left-0 w-screen h-screen z-10 backdrop-filter backdrop-blur-sm" />
        </>
      )}

      <nav
        className={clsx(
          "fixed p-5 right-0 top-0 w-full md:w-[400px] h-screen bg-white z-20 shadow-2xl transform transition-all duration-300",
          {
            "translate-x-full": !isSideMenuOpen,
          }
        )}
      >
        <div className="absolute top-4 right-4 cursor-pointer" onClick={closeMenu}>
          <TbSquareRoundedX className="w-10 h-10 hover:text-green-500" />
        </div>

        {productsInCart.length !== 0 ? (
          <div className="w-full mt-10 px-5">
            <ProductsSidebar data={productsInCart} />
            {nickInput && <p>Escribe un nick de un vendedor</p>}
            {nickInputFalse && <p>Escribe un nick de un vendedor válido</p>}
            {nickInputInput && (
              <input
                className="input-style w-full rounded-xl"
                placeholder="Nick"
                onChange={(e) => setnick(e.target.value)}
              />
            )}
            <TotalSidebar total={total} setComprar={setComprar} />
          </div>
        ) : (
          <div className="w-full mt-10 px-5">
            <span className="text-center block font-bold text-xl">
              No hay Productos
            </span>
          </div>
        )}
      </nav>
    </div>
  );
};
