import { Config } from "./Config";

const url = Config.api.local

export const HttpAuth = {

    Login: async ( data ) => {
        try{
            const headers = new Headers()
            headers.append('Content-Type', 'application/json');
            let consultaRegistro = await fetch(`${url}login`,{
                mode:'cors', 
                method: 'POST', 
                headers: headers, 
                body:JSON.stringify(data)
            })
            let resconsultaRegistro = await consultaRegistro.json();
            let consultaFinal = {
                status: consultaRegistro.status,
                data: resconsultaRegistro
            }
            return consultaFinal;
        }catch(err){
            console.log('error', err)
            return {error: 'Error de servidor'}
        }
    },
    Register: async ( data ) => {
        try{
            const headers = new Headers()
            headers.append('Content-Type', 'application/json');
            let consultaRegistro = await fetch(`${url}register`,{
                mode:'cors', 
                method: 'POST', 
                headers: headers, 
                body:JSON.stringify(data)
            })
            let resconsultaRegistro = await consultaRegistro.json();
            let consultaFinal = {
                status: consultaRegistro.status,
                data: resconsultaRegistro
            }
            return consultaFinal;
        }catch(err){
            console.log('error', err)
            return {error: 'Error de servidor'}
        }
    }

}