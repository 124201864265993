import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { HttpAuth } from "../../Api/HttpAuth"
import { userStore } from "../../Store"
import { useSeller } from "../../Context/SellerProvider"


export const Login = () => {

    const { seller } = useSeller();

    const userLogin = userStore(state => state.setUser)

    const navigate = useNavigate()

    const [errors, setErrors] = useState(null)
    const [formData, setFormData] = useState({})


    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrors(null);
        HttpAuth.Login(formData)
            .then((response) => {
                console.log(response)
                if(response.status === 200){
                    userLogin(response.data)
                    seller === "Login" ? navigate('/') : navigate(`/${seller}`)
                }else{
                    setErrors(response.data.user)
                    setTimeout(function(){
                        setErrors(null)
                    }, 2000);
                }
            })
    }

    return (
        <div className="min-h-[84.2vh] flex justify-center items-center">

            <div className="bg-custom-gray mx-7 w-full max-w-[570px] p-5 rounded-custom">
                <p className='text-4xl font-bold text-green-500 text-center'>
                    Login
                </p>
                <p className='text-lg text-center mt-3'>
                    Please login using account detail bellow.
                </p>
                <form onSubmit={handleSubmit}>
                    <input
                        type="email"
                        required
                        placeholder="email"
                        className='input-style w-full mt-10 mb-4'
                        onChange={event =>
                            setFormData(prevFormData => ({ ...prevFormData, email: event.target.value }))
                        }
                    />
                    <input
                        type="password"
                        required
                        placeholder="********"
                        className='input-style w-full mb-4'
                        onChange={event =>
                            setFormData(prevFormData => ({ ...prevFormData, password: event.target.value }))
                        }
                    />
                    <button type="submit" className="btn-primary w-full">
                        Entrar
                    </button>
                </form>
                <span className='block text-center mt-2 text-sm text-red-600'>
                    {errors}
                </span>
                <div className="flex justify-center items-center mt-4 hover:text-green-500">
                    <div className="cursor-pointer" onClick={() => {seller === "Login" ? navigate('/Register') : navigate(`/Register/${seller}`)}}>
                      Crear Cuenta
                    </div>
                </div>
            </div>

        </div>
    )
}