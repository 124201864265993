import clsx from "clsx"
import { useUIStore } from "../../../Store"
import { TbSquareRoundedX } from "react-icons/tb"
import { NavLink } from "react-router-dom"
import { useSeller } from "../../../Context/SellerProvider"

export const MenuMobil = () => {

    const isSideMenuMobilOpen = useUIStore(state => state.isSideMenuMobilOpen)
    const closeSideMenuMobil = useUIStore(state => state.closeSideMenuMobil)
    const { seller } = useSeller();

    const createLink = (path) => {
        if (path === "/") {
            return "/";
        }
        return seller ? `${path}/${seller}` : path;
    };

    return (
        <div>
            {isSideMenuMobilOpen && (
                <>
                    <div className="fixed top-0 left-0 w-screen h-screen z-10 bg-black opacity-30" />
                    <div onClick={closeSideMenuMobil} className="fade-in fixed top-0 left-0 w-screen h-screen z-10 backdrop-filter backdrop-blur-sm" />
                </>
            )}
            <nav
                className={clsx(
                    "fixed p-5 right-0 top-0 w-full h-screen bg-white z-20 shadow-2xl transform transition-all duration-300",
                    {
                        "translate-x-full": !isSideMenuMobilOpen,
                    }
                )}
            >
                <div className="absolute top-4 right-4 cursor-pointer" onClick={closeSideMenuMobil}>
                    <TbSquareRoundedX className="w-10 h-10 hover:text-green-500" />
                </div>
                <div className="flex flex-col justify-center items-center mt-10">
                    <NavLink to={createLink("/")} className='m-2 p-2 rounded-md transition-all hover:text-green-500 font-semibold' onClick={closeSideMenuMobil}>
                        Inicio
                    </NavLink>
                    <NavLink to={createLink("/About")} className='m-2 p-2 rounded-md transition-all hover:text-green-500 font-semibold' onClick={closeSideMenuMobil}>
                        Nosotros
                    </NavLink>
                    <NavLink to={createLink("/Products")} className='m-2 p-2 rounded-md transition-all hover:text-green-500 font-semibold' onClick={closeSideMenuMobil}>
                        Tienda
                    </NavLink>
                    <NavLink to={createLink("/Contact")} className='m-2 p-2 rounded-md transition-all hover:text-green-500 font-semibold' onClick={closeSideMenuMobil}>
                        Contáctenos
                    </NavLink>
                </div>
            </nav>
        </div>
    )
}
