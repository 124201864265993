import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const SellerContext = createContext();

export const useSeller = () => useContext(SellerContext);

export const SellerProvider = ({ children }) => {
  const location = useLocation();
  const [seller, setSeller] = useState(null);

  const validPaths = ['/', 'Login', 'Register', '/About', '/Products', '/Contact', 'Product', 'Paid']; // Lista de rutas válidas

  useEffect(() => {
    const paths = location.pathname.split('/');
    const lastSegment = paths[paths.length - 1];
    
    // Si el último segmento es una de las rutas válidas, no es un seller
    if (validPaths.includes(`/${lastSegment}`) || lastSegment === '') {
      setSeller(null);
    } else {
      setSeller(lastSegment);
    }
  }, [location]);

  return (
    <SellerContext.Provider value={{ seller, setSeller }}>
      {children}
    </SellerContext.Provider>
  );
};
